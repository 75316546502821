<template>
  <form @submit.prevent="createServiceTicket" class="relative">
    <div
      v-show="isBusy"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-20"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm"
        >Customer
        <span class="text-red-500">*</span>
      </label>
      <div
        class="fixed inset-0 overflow-hidden z-10"
        v-if="contactSelectOpen"
        @click="contactSelectOpen = false"
      ></div>
      <div class="relative mx-3">
        <div class="relative -mx-3">
          <input
            readonly
            @click="openSearchContact"
            v-model="contactName"
            class="w-full bge-input py-2 pl-4 pr-10 rounded cursor-pointer"
            type="text"
          />
          <button
            v-if="contactName"
            name="Clear Supplier"
            @click="clearContact"
            class="m-1 z-10 text-red-500 hover:bg-red-200 rounded-full absolute inset-y-0 right-0 flex items-center px-2 transition-colors duration-150 ease-in-out"
          >
            <svg
              class="stroke-current h-5 w-5"
              fill="none"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              viewBox="0 0 24 24"
            >
              <path d="M18 6L6 18M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div
          v-if="contactSelectOpen"
          class="z-10 absolute w-full max-h-5vh bg-white shadow-lg rounded border border-gray-300 top-0 mt-5 p-2"
        >
          <div class="relative flex flex-col">
            <input
              ref="tbSupplierSearch"
              type="text"
              v-model="contactSearchTerm"
              @keydown="searchContact"
              class="bge-input bge-input-spacing rounded"
              placeholder="Contact name (enter 3 letters or more to search)"
            />
            <div class="flex flex-col overflow-y-scroll bg-white mt-2">
              <template v-for="result in contactResults">
                <button
                  type="button"
                  :key="result.contact_id"
                  @click="selectContact(result)"
                  class="hover:bg-orange-200 flex items-center py-2 transition duration-100 focus:bg-orange-200 text-left px-2"
                >
                  <span class="ml-1">
                    {{ result.name }}
                    <span v-if="result.postcode">({{ result.postcode }})</span>
                  </span>
                </button>
              </template>
              <p class="mx-auto py-4 px-8" v-if="contactResults == ''">
                No contacts match your search
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="customersEquipment.length > 0" class="w-full mb-2">
      <label class="uppercase font-semibold text-sm">
        Equipment
      </label>
      <div class="w-full relative">
        <select
          class="bge-input bge-select rounded"
          v-model="serviceTicket.equipment_id"
        >
          <template v-for="item in customersEquipment">
            <option :key="item.equipment_id" :value="item.equipment_id"
              >{{ item.name }} (SN:{{ item.serial_number }})</option
            >
          </template>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm">Ticket Title</label>
      <input
        v-model="serviceTicket.title"
        required
        class="bge-input bge-input-spacing w-full rounded"
      />
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm">
        Severity
      </label>
      <div class="w-full relative">
        <select
          class="bge-input bge-select rounded"
          v-model="serviceTicket.severity"
        >
          <option value="0">Normal</option>
          <option value="1">Low</option>
          <option value="2">Medium</option>
          <option value="3">High</option>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm"
        >Contact Name (Additional)</label
      >
      <input
        v-model="serviceTicket.contact_name_additional"
        class="bge-input bge-input-spacing w-full rounded"
      />
    </div>
    <div class="w-full mb-2">
      <label class="uppercase font-semibold text-sm"
        >Contact Number (Additional)</label
      >
      <input
        v-model="serviceTicket.contact_number_additional"
        class="bge-input bge-input-spacing w-full rounded"
      />
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm"
        >Description of Issue</label
      >
      <textarea
        class="w-full bge-input bge-input-spacing rounded"
        v-model="serviceTicket.description"
        rows="5"
      />
    </div>
    <div class="mb-2">
      <label class="uppercase font-semibold text-sm">Follow Up At</label>
      <datepicker
        input-class="border rounded bg-gray-100 border-gray-400 hover:border-primary focus:border-primary py-2 px-4 focus:shadow w-full"
        v-model="serviceTicket.follow_up_at"
        format="dd/MM/yyyy"
      ></datepicker>
    </div>
    <div class="relative flex justify-end mt-5">
      <div
        v-show="!canSubmitForm"
        class="opacity-50 bg-white absolute inset-0"
      ></div>
      <button
        :disabled="isBusy || !canSubmitForm"
        type="submit"
        class="flex items-center bg-blue-200 hover:bg-blue-300 text-secondary py-1 px-3 rounded border border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-100"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save</span>
      </button>
    </div>
  </form>
</template>

<script>
const Spinner = () => import("@/components/Spinner.vue");
const Notification = () => import("@/components/Notification.vue");
const Toggle = () => import("@/components/Toggle.vue");
const Datepicker = () => import("vuejs-datepicker");

export default {
  name: "CreateServiceTicket",
  components: {
    Spinner,
    Toggle,
    Datepicker,
  },
  props: {
    providedContact: {
      type: Object,
    },
  },
  data() {
    return {
      isBusy: false,
      serviceTicket: {
        severity: 0,
      },
      contactName: "",
      contactSearchTerm: "",
      contactResults: [],
      contactSelectOpen: false,
      customersEquipment: [],
    };
  },
  computed: {
    canSubmitForm() {
      return this.serviceTicket.contact_id && this.serviceTicket.title;
    },
  },
  mounted() {
    if (this.providedContact) {
      this.selectContact(this.providedContact);
    }
  },
  methods: {
    openSearchContact: function() {
      this.contactSearchTerm = "";
      this.contactResults = [];
      this.contactSelectOpen = true;
      this.$nextTick(function() {
        this.$refs.tbSupplierSearch.focus();
      });
    },
    searchContact: _.debounce(async function() {
      this.contactResults = [];
      if (this.contactSearchTerm != "" && this.contactSearchTerm.length >= 3) {
        let results = await this.ContactService.searchContact(
          this.contactSearchTerm
        );
        this.contactResults = results.data.map((ct) => {
          let addresses = ct.addresses || [];
          let defaultAddress =
            this.$_.find(addresses, (addr) => addr.is_default) || {};
          console.log("Default address", defaultAddress);

          return {
            ...ct,
            postcode: defaultAddress.postcode,
          };
        });
        console.log(this.contactResults);
      }
    }, 500),
    selectContact: function(contact) {
      this.serviceTicket.contact_id = contact.contact_id;
      this.contactName = contact.name;
      this.contactSelectOpen = false;
      this.getEquipment();
    },
    clearContact: function() {
      this.serviceTicket.contact_id = "";
      this.contactName = "";
      this.$forceUpdate();
    },
    getEquipment: async function() {
      try {
        let result = await this.ContactService.getEquipment(
          this.serviceTicket.contact_id
        );
        this.customersEquipment = result.data;
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    },
    createServiceTicket: async function() {
      this.isBusy = true;
      try {
        this.serviceTicket.follow_up_at
          ? (this.serviceTicket.follow_up_at = this.$moment(
              this.serviceTicket.follow_up_at
            ).unix())
          : null;
        let result = await this.ServiceTicketService.logServiceTicket(
          this.serviceTicket
        );
        this.isBusy = false;
        this.$emit("complete");
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  title: "Success",
                  close: onClose,
                },
              },
              "Logged Service Ticket successfully."
            );
          },
          {
            position: "top-right",
          }
        );
      } catch (error) {
        console.error(error);
        this.isBusy = false;
        return this.$breadstick.notify(
          ({ h, onClose }) => {
            return h(
              Notification,
              {
                props: {
                  type: "danger",
                  title: "Error",
                  close: onClose,
                },
              },
              "There's been an error whilst logging this service ticket."
            );
          },
          {
            position: "top-right",
          }
        );
      }
    },
  },
};
</script>
